
export const AWS_REGIONS = [
    { "RegionName": "Europe (London)", "Code": "eu-west-2" },
    { "RegionName": "Europe (Ireland)", "Code": "eu-west-1" },
    { "RegionName": "Asia Pacific (Mumbai)", "Code": "ap-south-1" },
    { "RegionName": "US East (N. Virginia)", "Code": "us-east-1" },
    { "RegionName": "US East (Ohio)", "Code": "us-east-2" },
    { "RegionName": "US West (N. California)", "Code": "us-west-1" },
    { "RegionName": "US West (Oregon)", "Code": "us-west-2" },
    { "RegionName": "Africa (Cape Town)", "Code": "af-south-1" },
    { "RegionName": "Asia Pacific (Hong Kong)", "Code": "ap-east-1" },
    { "RegionName": "Asia Pacific (Tokyo)", "Code": "ap-northeast-1" },
    { "RegionName": "Asia Pacific (Seoul)", "Code": "ap-northeast-2" },
    { "RegionName": "Asia Pacific (Osaka)", "Code": "ap-northeast-3" },
    { "RegionName": "Asia Pacific (Hyderabad)", "Code": "ap-south-2" },
    { "RegionName": "Asia Pacific (Singapore)", "Code": "ap-southeast-1" },
    { "RegionName": "Asia Pacific (Sydney)", "Code": "ap-southeast-2" },
    { "RegionName": "Asia Pacific (Jakarta)", "Code": "ap-southeast-3" },
    { "RegionName": "Asia Pacific (Melbourne)", "Code": "ap-southeast-4" },
    { "RegionName": "Canada (Central)", "Code": "ca-central-1" },
    { "RegionName": "China (Beijing)", "Code": "cn-north-1" },
    { "RegionName": "China (Ningxia)", "Code": "cn-northwest-1" },
    { "RegionName": "Europe (Frankfurt)", "Code": "eu-central-1" },
    { "RegionName": "Europe (Zurich)", "Code": "eu-central-2" },
    { "RegionName": "Europe (Stockholm)", "Code": "eu-north-1" },
    { "RegionName": "Europe (Milan)", "Code": "eu-south-1" },
    { "RegionName": "Europe (Spain)", "Code": "eu-south-2" },
    { "RegionName": "Europe (Paris)", "Code": "eu-west-3" },
    { "RegionName": "Middle East (UAE)", "Code": "me-central-1" },
    { "RegionName": "Middle East (Bahrain)", "Code": "me-south-1" },
    { "RegionName": "South America (São Paulo)", "Code": "sa-east-1" },
    { "RegionName": "AWS GovCloud (US-East)", "Code": "us-gov-east-1" },
    { "RegionName": "AWS GovCloud (US-West)", "Code": "us-gov-west-1" }
]
