// src/App.tsx
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import routes from './routes';
import Layout from './components/Layout';

const AppRoutes = () => {
	const routing = useRoutes(routes);
	return routing;
};

function App() {
	return (
		<BrowserRouter>
			<Layout>
				<AppRoutes />
				<Toaster />
			</Layout>
		</BrowserRouter>
	);
}

export default App;
