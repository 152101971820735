// src/components/Layout.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/logo.svg'

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {

    const logoutAdmin = () => {
        window.localStorage.removeItem('session');
        window.location.href = '/';
    }

    const hasSession = () => window.localStorage.getItem('session')

    return (
        <div className="min-h-screen flex flex-col items-center shadow-xl">
            <header className="w-full px-3 py-2 shadow-md flex justify-between sticky">
                <Link to="/">
                    <img src={Logo} alt="Logo" className="h-12" />
                </Link>
                {hasSession() && <button onClick={logoutAdmin} type="button" className='px-2'><i className="fa-solid fa-sign-out"></i></button>}
            </header>
            <main className="flex-1 w-full flex items-center justify-center">
                {children}
            </main>
        </div>
    );
};

export default Layout;
