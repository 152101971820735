import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import useAxios from "../hooks/useAxios";
import toast from "react-hot-toast";

interface FormValues {
    ProjectName: string;
    Domain: string;
    Comment: string;
    CompanyID: string | undefined;
}

const AddWebsite: React.FC = () => {
    const { register, handleSubmit, formState: { errors }, setError } = useForm<FormValues>();
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();
    const axios = useAxios();
    const params = useParams();

    // Handler for form submission
    const onSubmit: SubmitHandler<FormValues> = async (website) => {
        try {
            setIsSaving(() => true);
            website['CompanyID'] = params.id;
            const { data } = await axios.post('create-website', website);
            if (data.status !== 'success') {
                setIsSaving(false);
                return setError(data.field, { message: data.message }, { shouldFocus: true });
            }
            toast.success(data.message, { position: 'top-right' })
            setIsSaving(false);
            navigate(`/company/${params.id}?tab=websites`)
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong!', { position: 'top-right' })
            setIsSaving(() => false)
        }
    };


    return (
        <div className="min-h-screen p-6 w-full">
            <div className="shadow-2xl p-3 border">
                <div className="justify-start flex py-3 mb-2">
                    <nav className="flex items-center text-gray-600 text-sm">
                        <i className="fas fa-home text-gray-500 mr-2"></i>
                        <Link to={'/companies'} className="hover:underline">Companies</Link>
                        <i className="fas fa-chevron-right text-gray-500 mx-2"></i>
                        <Link to={'/company/' + params.id+'?tab=websites'} className="hover:underline">Details</Link>
                        <i className="fas fa-chevron-right text-gray-500 mx-2"></i>
                        <span className="text-gray-500">New Website</span>
                    </nav>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className="p-4">
                    <div className="space-y-12">
                        <div className="border-b border-gray-900/10 pb-12">
                            <h2 className="text-lg font-semibold text-gray-900">Website Information</h2>
                            <p className="mt-1 text-sm text-gray-600">
                                Fill out the form below to configure the essential details for the new website
                            </p>
                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label htmlFor="website-name" className="block mb-2 text-sm font-medium text-gray-900">
                                        Website Name <span className="text-red-600">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="company-name"
                                        {...register("ProjectName", { required: "Website name is required" })}
                                        placeholder="Enter website name..."
                                        className="focus-visible:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    />
                                    {errors.ProjectName && <p className="text-red-600 text-sm">{errors.ProjectName.message}</p>}
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="domain" className="block mb-2 text-sm font-medium text-gray-900">
                                        Website Domain <span className="text-red-600">*</span>
                                    </label>
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600">
                                        <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm"><b>https://</b></span>
                                        <input
                                            id="domain"
                                            {...register("Domain", {
                                                required: "Website domain is required",
                                                pattern: {
                                                    value: /^(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/,
                                                    message: "Please enter a valid domain name",
                                                },
                                            })}
                                            placeholder="www.example.com"
                                            className="focus-visible:outline-none block flex-1 border-0 bg-transparent py-2.5 pl-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                        />
                                    </div>
                                    {errors.Domain && <p className="text-red-600 text-sm">{errors.Domain.message}</p>}
                                </div>


                                <div className="sm:col-span-3">
                                    <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Comment <span className="text-red-600">*</span></label>
                                    <textarea id="message" {...register("Comment", {
                                        required: "Comment is required",
                                    })} rows={4} className="focus-visible:outline-none block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Describe the purpose and creator..."></textarea>
                                    {errors.Comment && <p className="text-red-600 text-sm">{errors.Comment.message}</p>}
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button disabled={isSaving} type="button" className="text-sm font-semibold text-gray-900" onClick={() => navigate(`/company/${params.id}?tab=websites`)}>Cancel</button>
                        {
                            !isSaving ? (<button type="submit" className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                                Create
                            </button>) :
                                (<button disabled type="button" className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 focus-visible:outline-blue-600 inline-flex items-center">
                                    <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                    </svg>
                                    Creating...
                                </button>)
                        }
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddWebsite;
