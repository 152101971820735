import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxios from "../hooks/useAxios";
import moment from "moment";

const columns = [
    'Sr No.',
    'Company Name',
    'Database Name',
    'Account ID',
    'Created On'
]


const Companies: React.FC = () => {

    const navigate = useNavigate()
    const axios = useAxios()

    const [companies, setCompanies] = useState([]);

    const fetchAllCompanies = async () => {
        try {
            const { data } = await axios.get('companies');
            setCompanies(() => data);
        } catch (error) {
            console.log(error);

        }
    }

    useEffect(() => {
        fetchAllCompanies()
    }, [])


    return (
        <div className="min-h-screen p-6 w-full">

            <div className="shadow-xl p-3 min-h-screen">
                <div className="justify-between flex py-3 mb-2">
                    <nav className="flex items-center text-gray-600 text-sm">
                        <i className="fas fa-home text-gray-500 mr-2"></i>
                        <a href="/" className="hover:underline">
                            Companies
                        </a>
                    </nav>
                    <div className="flex justify-between">
                        <div className="mx-2">
                            <form className="flex items-center max-w-lg mx-auto">
                                <label htmlFor="voice-search" className="sr-only">Search</label>
                                <div className="relative w-full">
                                    <input type="text" id="voice-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus-visible:outline-none block w-full p-2" placeholder="Search..." required />
                                    <button type="button" className="focus-visible:outline-none absolute inset-y-0 end-0 flex items-center pe-3">
                                        <i className="fa-solid fa-search w-4 h-4 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"></i>
                                    </button>

                                </div>
                            </form>
                        </div>
                        <button type="button" onClick={() => navigate('/company/new')} className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-blue-500 to-blue-500 group-hover:from-blue-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-200 dark:focus:ring-blue-800">
                            <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                <i className="fa-solid fa-plus"></i> New
                            </span>
                        </button>
                    </div>
                </div>

                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            {columns.map((column, index) => (<th key={index + 'col'} scope="col" className="px-6 py-3">{column}</th>))}
                        </tr>
                    </thead>
                    <tbody>
                        {companies.map((comp: any, index: number) => (<tr key={index + '_companies'} onClick={() => navigate('/company/' + comp._id)} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 cursor-pointer">
                            <td className="px-6 py-4">{index + 1}</td>
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {comp.Name}
                            </th>
                            <td className="px-6 py-4">
                                {comp.DBName}
                            </td>
                            <td className="px-6 py-4">
                                {comp.AccountID}
                            </td>
                            <td className="px-6 py-4">
                                {moment(comp.CreatedDate).format('DD MMM, YYYY')}
                            </td>
                        </tr>))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Companies;