import React from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import useAxios from '../hooks/useAxios';

type LoginData = {
    Email: string;
    Password: string;
};

const LoginForm: React.FC = () => {
    const { register, handleSubmit, formState: { errors }, setError } = useForm<LoginData>();
    const axios = useAxios()

    const onSubmit = async (login: LoginData) => {
        try {
            const { data } = await axios.post('login', login);
            if (data.status !== 'success') {
                return setError(data.field, { message: data.message }, { shouldFocus: true })
            }
            window.localStorage.setItem('session', data.token);
            window.location.href = '/companies';
        } catch (error) {
            toast.error('Something went wrong!')
        }
    };

    return (
        <div className="w-full max-w-md p-8 rounded-lg shadow-2xl">
            <h2 className="text-3xl font-bold mb-6 text-center">Login</h2>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                <div>
                    <label htmlFor="email" className="block text-sm font-medium">
                        Email Address
                    </label>
                    <input
                        type="email"
                        id="Email"
                        {...register('Email', {
                            required: 'Email is required',
                            pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                message: 'Invalid email address',
                            },
                        })}
                        className="mt-1 block w-full p-3  border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter your email"
                    />
                    {errors.Email && (
                        <p className="text-red-500 text-sm mt-1">{errors.Email.message}</p>
                    )}
                </div>

                <div>
                    <label htmlFor="Password" className="block text-sm font-medium">
                        Password
                    </label>
                    <input
                        type="password"
                        id="Password"
                        {...register('Password', {
                            required: 'Password is required'
                        })}
                        className="mt-1 block w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter your password"
                    />
                    {errors.Password && (
                        <p className="text-red-500 text-sm mt-1">{errors.Password.message}</p>
                    )}
                </div>

                <button
                    type="submit"
                    className="w-full py-3 mt-4 bg-blue-600 hover:bg-blue-700 rounded-lg text-white font-bold transition duration-200"
                >
                    Sign In
                </button>
            </form>
        </div>
    );
};

export default LoginForm;
