
export const isPascalCase = (value: string) => /^[A-Z][a-zA-Z]*$/.test(value);

export const generateStrongPassword = (length = 12) => {
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const specialChars = "!@#$&*_";

    let password = "";

    // Ensure at least one character from each category
    password += uppercase[Math.floor(Math.random() * uppercase.length)];
    password += lowercase[Math.floor(Math.random() * lowercase.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];
    password += specialChars[Math.floor(Math.random() * specialChars.length)];

    // Calculate remaining length and distribute more evenly
    const remainingLength = length - 4;
    const characterPools = [uppercase, lowercase, numbers, specialChars];
    for (let i = 0; i < remainingLength; i++) {
        const pool = characterPools[i % 3]; // Avoid picking from specialChars too often
        password += pool[Math.floor(Math.random() * pool.length)];
    }

    // Shuffle the password to make it more secure
    password = password.split('').sort(() => 0.5 - Math.random()).join('');
    return password;
};