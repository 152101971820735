import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAxios from "../hooks/useAxios";

const ViewCompany: React.FC = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const [activeTab, setActiveTab] = useState(queryParams.get('tab') || "info");
    const [company, setCompany] = useState<any>(null)
    const navigate = useNavigate();
    const axios = useAxios();
    const params = useParams();

    const fetchCompany = async () => {
        try {
            const { data } = await axios.get('company/' + params.id);
            setCompany(data)
        } catch (error) {
            console.log(error);

        }
    }

    useEffect(() => {
        fetchCompany()
    }, [])

    return (
        <div className="min-h-screen p-6 w-full">
            <div className="shadow-2xl p-3 min-h-screen">
                <div className="justify-start flex py-3 mb-2">
                    <nav className="flex items-center text-gray-600 text-sm">
                        <i className="fas fa-home text-gray-500 mr-2"></i>
                        <Link to={'/companies'} className="hover:underline">Companies</Link>
                        <i className="fas fa-chevron-right text-gray-500 mx-2"></i>
                        <span className="text-gray-500">Details</span>
                    </nav>
                </div>
                <div className="p-4">
                    {/* Tabs */}
                    <div className="flex border-b border-gray-200 mb-4">
                        <button
                            className={`py-2 px-4 text-sm font-medium ${activeTab === "info"
                                ? "border-b-2 border-blue-500 text-blue-600"
                                : "text-gray-600 hover:text-gray-800"
                                }`}
                            onClick={() => setActiveTab("info")}
                        >
                            General
                        </button>
                        <button
                            className={`py-2 px-4 text-sm font-medium ${activeTab === "websites"
                                ? "border-b-2 border-blue-500 text-blue-600"
                                : "text-gray-600 hover:text-gray-800"
                                }`}
                            onClick={() => setActiveTab("websites")}
                        >
                            Websites
                        </button>

                        {/* <button
                            className={`py-2 px-4 text-sm font-medium ${activeTab === "statistics"
                                ? "border-b-2 border-blue-500 text-blue-600"
                                : "text-gray-600 hover:text-gray-800"
                                }`}
                            onClick={() => setActiveTab("statistics")}
                        >
                            Statistics
                        </button> */}
                    </div>

                    {/* Tab Content */}
                    {activeTab === "info" && (
                        <div className="space-y-6">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div className="p-6">
                                    <h3 className="text-sm font-medium text-gray-500">Company Name</h3>
                                    <p className="text-base font-medium text-gray-900 mt-1">{company?.Name}</p>
                                </div>
                                <div className="p-6">
                                    <h3 className="text-sm font-medium text-gray-500">Company Type</h3>
                                    <p className="text-base font-medium text-gray-900 mt-1">{!company?.FormsOnly ? 'All Features' : 'Forms Only'}</p>
                                </div>
                                <div className="p-6">
                                    <h3 className="text-sm font-medium text-gray-500">Database Name</h3>
                                    <p className="text-base font-medium text-gray-900 mt-1">{company?.DBName}</p>
                                </div>
                                <div className="p-6">
                                    <h3 className="text-sm font-medium text-gray-500">AWS Region</h3>
                                    <p className="text-base font-medium text-gray-900 mt-1">{company?.AWSRegion}</p>
                                </div>
                            </div>
                        </div>

                    )}

                    {activeTab === "websites" && (
                        <div className="space-y-4">
                        {company?.CMS?.length ? (
                            // If there are websites, display the existing layout
                            <div className="flex justify-between items-center">
                                <h2 className="text-xl font-semibold text-gray-800">Websites</h2>
                                <button
                                    type="button"
                                    onClick={() => navigate(`/company/${params.id}/website/new`)}
                                    className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-blue-500 to-blue-500 group-hover:from-blue-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-200 dark:focus:ring-blue-800"
                                >
                                    <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                        <i className="fa-solid fa-plus"></i> New
                                    </span>
                                </button>
                            </div>
                        ) : (
                            // Center the button both horizontally and vertically when there are no websites
                            <div className="flex justify-center items-center h-64"> {/* Adjust the height as needed */}
                                <button
                                    type="button"
                                    onClick={() => navigate(`/company/${params.id}/website/new`)}
                                    className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-blue-500 to-blue-500 group-hover:from-blue-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-200 dark:focus:ring-blue-800"
                                >
                                    <span className="relative px-8 py-3 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                        <i className="fa-solid fa-plus"></i> Add Website
                                    </span>
                                </button>
                            </div>
                        )}
                    
                        {company?.CMS?.length ? (
                            <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                                {company.CMS.map((website: any, index: number) => (
                                    <Link
                                        to={`/company/${params.id}/website/${website._id}`}
                                        key={index}
                                        className="p-6 bg-blue-100 rounded-lg hover:shadow-lg transition-shadow duration-300 hover:bg-blue-200"
                                    >
                                        <div className="cursor-pointer">
                                            <h3 className="text-base font-semibold text-gray-800">{website.ProjectName}</h3>
                                            <p className="text-sm text-gray-500 mt-1">{website.Domain}</p>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        ) : null}
                    </div>
                    
                    )}
                </div>
            </div>
        </div>
    );
}

export default ViewCompany;
