// src/routes.tsx
import { RouteObject } from 'react-router-dom';
import Login from './pages/Login';
import Companies from './pages/Companies';
import AddCompany from './pages/AddCompany';
import ViewCompany from './pages/ViewCompany';
import AddWebsite from './pages/AddWebsite';
import ViewWebsite from './pages/ViewWebsite';
import RequireAuth from './components/RequireAuth';
import AlreadyAuthenticated from './components/AlreadyAuthenticated';

const routes: RouteObject[] = [
    {
        element: <AlreadyAuthenticated />, // Public route with redirection if already authenticated
        children: [
            {
                path: '/',
                element: <Login />,
            },
        ],
    },
    {
        element: <RequireAuth />, // Route guard for protected routes
        children: [
            {
                path: '/companies',
                element: <Companies />,
            },
            {
                path: '/company/new',
                element: <AddCompany />,
            },
            {
                path: '/company/:id',
                element: <ViewCompany />,
            },
            {
                path: '/company/:id/website/new',
                element: <AddWebsite />,
            },
            {
                path: '/company/:id/website/:websiteId',
                element: <ViewWebsite />,
            },
        ],
    },
];

export default routes;
