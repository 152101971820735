import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import useAxios from "../hooks/useAxios";
import moment from "moment";

const ViewWebsite: React.FC = () => {
    const [OPTCompanies, setOPTCompanies] = useState<any[]>([]);
    const [formIntegratedTo, setFormIntegratedTo] = useState<string>('');
    const [website, setWebsite] = useState<any>(null);

    const axios = useAxios();
    const params = useParams();

    // Fetch website data
    const fetchWebsite = async () => {
        try {
            const { data } = await axios.get(`website/${params.id}/${params.websiteId}`);
            setWebsite(data);
        } catch (error) {
            console.log(error);
        }
    };

    // Fetch optimiser companies
    const fetchOPTCompanies = async () => {
        try {
            const { data } = await axios.get('optimiser-companies');
            setOPTCompanies(data);
        } catch (error) {
            console.log(error);
        }
    };

    // Handle form integration
    const handleIntegrateAction = async () => {
        try {
            const { data } = await axios.post('integrate-forms', {
                WebSiteID: params.websiteId,
                OPTCompanyID: formIntegratedTo,
                TPCompanyID: params.id,
            });

            if (data.status !== 'success') {
                return toast.error(data.message, { position: 'top-right' });
            }

            toast.success(data.message, { position: 'top-right' });
            fetchWebsite(); // Refresh website data after action
        } catch (error) {
            toast.error('Something went wrong!', { position: 'top-right' });
            console.log(error);
        }
    };

    // Set the form integration state when `website` or `OPTCompanies` change
    useEffect(() => {
        if (website && OPTCompanies.length > 0) {
            const comp = OPTCompanies.find((o: any) => o._id === website?.FormIntegratedTo);
            if (comp) {
                setFormIntegratedTo(comp.DBName);
            }
        }
    }, [website, OPTCompanies]);

    // Initial fetch on component mount
    useEffect(() => {
        fetchWebsite();
        fetchOPTCompanies();
    }, []); // Empty dependency array ensures this runs only once on mount

    return (
        <div className="min-h-screen p-6 w-full">
            <div className="shadow-2xl p-3 min-h-screen">
                <div className="justify-start flex py-3 mb-2">
                    <nav className="flex items-center text-gray-600 text-sm">
                        <i className="fas fa-home text-gray-500 mr-2"></i>
                        <Link to={'/companies'} className="hover:underline">Companies</Link>
                        <i className="fas fa-chevron-right text-gray-500 mx-2"></i>
                        <Link to={'/company/' + params.id+'?tab=websites'} className="hover:underline">Details</Link>
                        <i className="fas fa-chevron-right text-gray-500 mx-2"></i>
                        <span className="text-gray-500">View Website</span>
                    </nav>
                </div>
                <form className="p-4">
                    <div className="space-y-12">
                        <div className="border-b border-gray-900/10 pb-12">
                            <h2 className="text-lg font-semibold text-gray-900">General Information</h2>
                            <p className="mt-1 text-sm text-gray-600">
                                Provide the company's name, type, and the database name to create a new company profile.
                            </p>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <h3 className="text-sm font-medium text-gray-500">Website Name</h3>
                                    <p className="text-base font-medium text-gray-900 mt-1">{website?.ProjectName}</p>
                                </div>
                                <div className="sm:col-span-3">
                                    <h3 className="text-sm font-medium text-gray-500">Website Domain</h3>
                                    <p className="text-base font-medium text-gray-900 mt-1">{website?.Domain}</p>
                                </div>
                                <div className="sm:col-span-3">
                                    <h3 className="text-sm font-medium text-gray-500">CDN URL</h3>
                                    <p className="text-base font-medium text-gray-900 mt-1">{website?.CDNName}</p>
                                </div>
                                <div className="sm:col-span-3">
                                    <h3 className="text-sm font-medium text-gray-500">Slug</h3>
                                    <p className="text-base font-medium text-gray-900 mt-1">{website?.ProjectSlug}</p>
                                </div>
                                <div className="sm:col-span-3">
                                    <h3 className="text-sm font-medium text-gray-500">Description</h3>
                                    <p className="text-base font-medium text-gray-900 mt-1">{website?.Comment}</p>
                                </div>
                                <div className="sm:col-span-3">
                                    <h3 className="text-sm font-medium text-gray-500">Created Date</h3>
                                    <p className="text-base font-medium text-gray-900 mt-1">{moment(website?.CreatedDate).format('DD MMM, YYYY')}</p>
                                </div>
                            </div>
                        </div>

                        <div className="border-b border-gray-900/10 pb-8">
                            <h2 className="text-lg font-semibold text-gray-900">Optimiser Forms Integration</h2>
                            <p className="mt-1 text-sm text-gray-600">
                                Allows integrating the Forms module of Optimiser Touchpoint into Optimiser CRM.
                            </p>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    {!website?.FormIntegratedTo ? (<div className="flex items-center">
                                        <select
                                            id="awsRegion"
                                            onChange={(e) => setFormIntegratedTo(e.target.value)}
                                            className="bg-gray-50 focus-visible:outline-none border border-gray-300 text-gray-900 text-sm rounded-l-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        >
                                            <option selected value={''}>Select a company</option>
                                            {OPTCompanies?.map((reg: any, index: number) => (
                                                <option key={index} value={reg._id}>
                                                    {reg.DBName}
                                                </option>
                                            ))}
                                        </select>
                                        <button
                                            disabled={!formIntegratedTo}
                                            type="button"
                                            onClick={handleIntegrateAction}
                                            className="bg-blue-500 text-white rounded-r-lg p-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 border border-l-0 border-blue-500"
                                        >
                                            Connect
                                        </button>

                                    </div>) : (<div className="flex items-center space-x-2">
                                        <i className="fa-solid fa-check-circle text-green-500 text-2xl"></i> {/* Green tick icon */}
                                        <p className="text-gray-800 text-base font-medium">
                                            The form is connected to <b>{formIntegratedTo}</b>
                                        </p>
                                    </div>)}
                                    {!website?.FormIntegratedTo ? <div className="mt-2 items-center p-4 mb-4 text-sm text-blue-600 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
                                        <div>
                                            <i className="fa-solid fa-info-circle"></i> This action will automatically configure the CRM company with the associated Account ID and Website ID from Optimiser Touchpoint. Please ensure you have selected the correct company database, as this configuration will affect how forms data is shared and managed.
                                        </div>
                                    </div> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ViewWebsite;
