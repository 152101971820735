import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';

const useAxios = (): AxiosInstance => {
	const instance = axios.create({
		baseURL: '/api/',
	});

	// Request Interceptor
	instance.interceptors.request.use((config: AxiosRequestConfig): any => {
		const sessionToken = localStorage.getItem('session');
		config.headers = {
			...config.headers,
			"Authorization": sessionToken ? `Bearer ${sessionToken}` : "",
		};
		return config;
	}, Promise.reject);

	// Response Error Handling
	instance.interceptors.response.use(response => response, (error: AxiosError) => {
		// Custom error handling based on the response
		if (error.response) {
			const { data }: any = error.response;

			if (data.code && data.code === "INVALID_TOKEN") {
				window.localStorage.removeItem('session')
				window.location.href = '/'
			}
		}
		return Promise.reject(error);
	});

	return instance;
};

export default useAxios;
